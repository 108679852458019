body {
  margin: var(--neo-space-0x);
  .gray-color {
    background-color: var(--border-tooltip-color);
  }
  .text-left { text-align: left;}
  .text-right { text-align: right;}
}

neo-breadcrumb {
  svg {
    width: 25px;
  }
  .disable-link {
    pointer-events: none;
    cursor: default;
  }
}

neo-flag {
  cursor: pointer;
  i.wi {
    float:left;
    margin-top: 2px;
  }
  &:hover {
    color: var(--color-button-primary-hover);
    i.wi {color: var(--color-button-primary-hover);}
  }
}

neo-tooltip {
  top:5px !important;
  .neo-tooltip {
    .tooltip-content {
      padding: 5px !important;
    }
  }
}

#results-table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  thead {
    tr {
      th {
        white-space: nowrap;
        padding: var(--neo-space-5x) 0;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid var(--color-toggle-fill-deactivated-disabled);
      td {
        position: relative;
        app-copy {
          display: none;
        }
        img {
          width: 145px;
        }
        .status {
          height: 90px;
          width: var(--neo-space-1x);
          float: left;
          margin-right: var(--neo-space-4x);
        }
        .status.IN_PROGRESS {background: var(--border-text-area-warning-enabled-color);}
        .status.SUCCESSFUL {background: var(--color-text-area-status-success);}
        .status.FAILED {background: var(--color-toggle-switch-icon-deactivated-error);}
        .archived.false {display: none;}
        .archived.true {
          color: var(--color-text-area-background-focus);
          background-color: var(--color-text-area-text-entry-disabled);
          text-align: center;
          font-size: 10px;
          padding: var(--neo-space-05x) var(--neo-space-2x);
          border-radius: var(--neo-space-2x);
          position: absolute;
          margin: var(--neo-space-3x);
        }
      }
      td:hover {
        app-copy {
          display: block;
          cursor: pointer;
          position: absolute;
          top: 25%;
          width: 100%;
          height: 75%;
          background-color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
}

.successBg {
  .modalContentText {
    position: absolute;
    background: var(--color-text-area-status-success);
    top: 0;
    width: 100%;
    left: 0;
    bottom: 0;
    padding:var(--neo-space-10) var(--neo-space-5x);
  }
}

.errorBg {
  .modalContentText {
    position: absolute;
    background: var(--color-toggle-switch-icon-deactivated-error);
    top: 0;
    width: 100%;
    left: 0;
    bottom: 0;
    padding:var(--neo-space-10) var(--neo-space-5x);
  }
}

.webcom-date-range-picker .input {
  background-color: var(--color-input-background-enabled) !important;
  outline: var(--border-input-enabled-color) var(--border-input-enabled-style) var(--border-input-enabled-width) !important;
  outline-offset: calc(var(--border-input-enabled-width)* -1) !important;
  border-radius: var(--border-radius-input-sm) !important;
  display: flex !important;
  align-items: center !important;
  flex: 1 0 auto !important;
  height: var(--sizing-input-height-lg) !important;
  width: 100% !important;
  box-sizing: border-box !important;
  gap: var(--spacing-input-lg-text-gap-field) !important;
  padding: 0 var(--spacing-input-lg-text-padding-horizontal) !important;
  text-transform: lowercase;
}

.webcom-date-range-picker .input_label {
  text-transform: capitalize !important;
}
